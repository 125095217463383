<template>
<!--    <div class="card bs-3">-->
    <div class="">
        <img :src="require('@/assets/images/offers/' + img)" class="img-fluid" alt="#">
    </div>
</template>

<script>
export default {
    name: 'offer-box',
    props: {
        img: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>

</style>
