<template>
    <div class="fl-x-c mb-5" data-aos="fade-up" data-aos-duration="600">
        <div>
            <img :src="require('@/assets/images/discount-icon/' + img)" class="offer-icon" alt="">
        </div>
        <div class="ml-4">
            <p class="underline u-primary font-weight-bold" v-if="desc" v-html="desc">Flat 15% Discount</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'offer-details',
    props: {
        desc: {
            type: String,
            required: false,
            default: ''
        },
        img: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>
.offer-icon {
    width: 7rem;
}

</style>
