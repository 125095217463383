<template>
    <div class=" fl-x-tc mb-5">
        <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12 text-center underline u-primary u-c" data-aos="zoom-in"
             data-aos-duration="400">
            <h3 v-if="heading" v-html="heading" class="mb-4"></h3>
            <p v-if="headingdesc" v-html="headingdesc" class="description fs--1"></p>
            <!--            <p v-if="headingsubdesc" v-html="headingsubdesc" class="sub-description fs&#45;&#45;1"></p>-->
        </div>
    </div>
</template>

<script>
export default {
    name: 'Heading',
    props: {
        heading: {
            type: String,
            required: false,
            default: ''
        },
        headingdesc: {
            type: String,
            required: false,
            default: ''
        }
        // headingsubdesc: {
        //     type: String,
        //     required: false,
        //     default: ''
        // }
    }
};
</script>

<style scoped>
/*.description {*/
/*    color: #9c9c9c;*/
/*}*/

h3 {
    font-family: 'DIN-bold';
}

.underline:after {
    width: 80px;
}

/*.sub-description {*/
/*    color: #505050;*/
/*}*/
</style>
