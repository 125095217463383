<template>
    <div class="contact-us">
        <div class="pos-r c-bg-1">
            <div class="bg-container">
                <img class="layout-4" src="../assets/images/layout/layout4.png" alt="">
                <img class="layout-7" src="../assets/images/layout/layout7.png" alt="">
            </div>
            <div class="container py-5 h-100vh fl-eq-c">
                <div>
                    <div class="bs mt-5 bg-2">
                        <div class="row">
                            <div class="col-12 col-lg-6 fl-x-c mb-3 mb-lg-0 pr-lg-0">
                                <div class="p-4 contact-form-cont">
                                    <p class="sub-hero-text text-secondary">Contact Us</p>
<!--                                    <p>A medical distribution network concern, at Thrissur since 1926. Now, this is your-->
<!--                                        guide to immunity, preventive care and protection.</p>-->
                                    <transition name="fade" mode="out-in">
                                        <div class="alert alert-success" v-if="state === 'S'" key="'S">
                                            <div class="text-center p-4">
                                                <svg version="1.1" viewBox="0 0 130.2 130.2">
                                                    <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10"
                                                            cx="65.1" cy="65.1" r="62.1"/>
                                                    <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round"
                                                              stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                                                </svg>
                                                <p class="c-font-19 mb-4 proxim-light">
                                                    The mail has been successfully submitted.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="alert alert-danger" v-else-if="state === 'F'" key="'F">
                                            <div class="text-center p-4">
                                                <svg version="1.1" viewBox="0 0 130.2 130.2">
                                                    <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10"
                                                            cx="65.1" cy="65.1" r="62.1"/>
                                                    <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round"
                                                          stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                                                    <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round"
                                                          stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                                                </svg>
                                                <p class="c-font-19 mb-4 proxim-light">
                                                    Server error , Please try after sometime.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="alert alert-info" v-else-if="state === 'L'" key="'L">
                                            <div class="text-center p-4">
                                                <loading-animation/>
                                                <p class="c-font-19 mb-4 proxim-light">Please wait while communicate
                                                    with the server</p>
                                            </div>
                                        </div>
                                        <s-form class="contact-form row" v-else role="form" @submit="onSubmit"
                                                autocomplete="off">
                                            <div class="col-12 col-lg-6">
                                                <validated-input name="Name" v-model="name" shadow="0"
                                                                 placeholder="Name" :rules="rules.name"
                                                                 required/>
                                            </div>
                                            <div class="col-12 col-lg-6">
                                                <validated-mask-input name="Phone" v-model="phone" shadow="0"
                                                                      mask="##########" placeholder="Phone"
                                                                      :rules="rules.phone_number"/>
                                            </div>
                                            <div class="col-12">
                                                <validated-input type="email" name="Email" v-model="email" shadow="0"
                                                                 placeholder="Email" :rules="rules.email_id"/>
                                            </div>
                                            <div class="col-12 ">
                                                <validated-text-area placeholder="Message" v-model="message"
                                                                     :rules="rules.message1" shadow="0"/>
                                            </div>
                                            <div class="mt-4 col-12">
                                                <button class="lego-btn px-4 bg-primary btn-pill"
                                                        type="submit">
                                                    SUBMIT
                                                </button>
                                            </div>
                                        </s-form>
                                    </transition>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 fl-eq-c pl-lg-0">
                                <div id="map" style="display: none"
                                     class="embed-responsive embed-responsive-16by9"></div>
                                <div class="mapouter h-100">
                                    <div class="gmap_canvas h-100">
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3922.6080789394646!2d76.20898411434486!3d10.531502766547536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7ef84d44a4dd3%3A0x25900a0720e03b64!2sMEDICO%20PHARMA!5e0!3m2!1sen!2sus!4v1626949747752!5m2!1sen!2sus"
                                            width="100%" height="100%" style="border:0;" allowfullscreen=""
                                            loading="lazy"></iframe>
                                        <a href="https://putlocker-is.org"></a><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-12 col-lg fl-x-tc mb-3 mb-lg-0">
                            <span class="mr-2 text-primary fs-3"><i class="fa fa-phone"></i></span>
                            <div>
                                <p class="mb-0 mt-1"><a href="tel:+91 7557556040">+91 755 755 60 40</a></p>
                            </div>
                        </div>
                        <div class="col-12 col-lg fl-x-tc mb-3 mb-lg-0">
                            <span class="mr-2 text-primary fs-3"><i class="fa fa-envelope"></i></span>
                            <div>
                                <p class="mb-0 mt-1">
                                    <a href="mailto:medicopharma.tcr@gmail.com" target="_blank">
                                        medicopharma.tcr@gmail.com</a>
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-lg fl-x-tc mb-3 mb-lg-0">
                            <span class="mr-2 text-primary fs-3"><i class="fa fa-map-marker"></i></span>
                            <div>
                                <p class="mb-0 mt-1">29/702-F, Kalyanram Residency</p>
                                <p class="mb-0">Shornur Road, Thrissur - 680 022</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import LoadingAnimation from './components/LoadingAnimation';

export default {
    name: 'Contact-us',
    components: { LoadingAnimation },
    data () {
        return {
            state: 'D',
            name: '',
            phone: '',
            email: '',
            message: '',
            rules: {
                name: {
                    required: true
                },
                email_id: {
                    required: true
                },
                phone_number: {
                    required: true,
                    min: 10,
                    max: 10
                },
                message1: {
                    required: true
                }
            }
        };
    },
    methods: {
        onSubmit () {
            const bodyFormData = new FormData();
            bodyFormData.append('name', this.name);
            bodyFormData.append('email', this.email);
            bodyFormData.append('phone', this.phone);
            bodyFormData.append('message', this.message);

            const that = this;
            this.state = 'L';

            axios({
                method: 'post',
                url: 'https://medicopharma.co.in/mail.php',
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(function (response) {
                that.onSubmitSuccess(response);
            }).catch(function (response) {
                that.onSubmitFailed(response);
            });
        },

        onSubmitSuccess () {
            this.state = 'S';
        },

        onSubmitFailed () {
            this.state = 'F';
        }
    }
};
</script>

<style scoped lang="scss">
.bg-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    .layout-4 {
        position: absolute;
        left: 0;
        top: 0;
        width: 10%;
    }

    .layout-7 {
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
    }
}

</style>
