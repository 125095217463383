<template>
    <a class="content card bs-5 m-1">
        <div class="content-overlay"></div>
        <img v-if="img" :src="require('@/assets/images/products/' + img)" class="content-image" alt="#">
        <div class="content-details fadeIn-bottom">
            <p class="content-title" v-if="name" v-html="name"></p>
        </div>
    </a>
</template>

<script>
export default {
    name: 'equipments',
    props: {
        img: {
            type: String,
            required: false,
            default: ''
        },
        name: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped lang="scss">

.content {
    position: relative;
    overflow: hidden;
    display: block;

    .content-overlay {
        background: rgba(0, 0, 0, 0.7);
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        -webkit-transition: all 0.4s ease-in-out 0s;
        -moz-transition: all 0.4s ease-in-out 0s;
        transition: all 0.4s ease-in-out 0s;
    }

    .content-image {
        width: 100%;
    }

    .content-details {
        position: absolute;
        text-align: center;
        padding-left: 1rem;
        padding-right: 1rem;
        width: 100%;
        top: 50%;
        left: 50%;
        opacity: 0;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;

        .content-title {
            color: #fff;
            font-weight: 600;
            margin-bottom: 0;
            font-size: 1.3rem;
        }

        &.fadeIn-bottom {
            top: 80%;
        }
    }

    &:hover {
        .content-overlay {
            opacity: 1;
        }

        .content-details {
            top: 50%;
            left: 50%;
            opacity: 1;
        }
    }
}

</style>
