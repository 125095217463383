<template>
    <div class="content card bs-3 text-center p-4 m-1 h-100">
        <div>
            <img src="../../assets/images/quote.png" class="quotes" alt="#">
        </div>
        <p v-if="comment" v-html="comment" class="underline u-primary u-c fs--1"></p>
        <p v-if="person_name" v-html="person_name" class="font-weight-bold mb-0 mt-auto"></p>
        <p v-if="place" v-html="place" class="mb-0 fs--2"></p>
    </div>
</template>

<script>
export default {
    name: 'community',
    props: {
        comment: {
            type: String,
            required: false,
            default: ''
        },
        person_name: {
            type: String,
            required: false,
            default: ''
        },
        place: {
            type: String,
            required: false,
            default: ''
        }
    }
}
;
</script>

<style scoped lang="scss">
.content {
    display: flex;
    flex-direction: column;
}

.quotes {
    width: 25px;
    margin-bottom: 20px;
}
</style>
