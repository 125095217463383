<template>
    <div class="about">
        <div class="pos-r c-bg-1">
            <div class="bg-container">
                <img class="layout-4" src="../assets/images/layout/layout4.png" alt="">
                <img class="layout-8" src="../assets/images/layout/layout8.png" alt="">
            </div>
            <div class="container py-5 h-100vh fl-eq-c">
                <div class="row">
                    <div class="col-12 col-lg-6 fl-x-cc mb-5 mb-lg-0">
                        <img src="../assets/images/about.png" class="img-fluid hero-img" data-aos="zoom-in"
                             data-aos-duration="700" alt="">
                    </div>
                    <div class="col-12 col-lg-6 order-lg-first fl-eq-c">
                        <div data-aos="fade-right" data-aos-duration="700">
                            <p class="hero-text">About Us</p>
                            <p>We have been a part of the chain of medical network for the last two and half decades. Of
                                late, in the current pandemic environment, our Founder, a young entrepreneur,with his
                                humble life style and rich experience in the field of health, observed that a common man
                                needs to be self sufficient with essential stock of medicines, minimum surgical and
                                adequate medical awareness. This idea is the motivation behind opening retail outlet and
                                stock points for round-the-clock home delivery, at the time of Covid scenario.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pos-r">
            <div class="bg-container">
                <img class="layout-6" src="../assets/images/layout/layout2.png" alt="">
            </div>
            <div class="container py-5 fl-eq-c">
                <div class="row overflow-h">
                    <div class="col-12 col-lg-6 fl-eq-c">
                        <div data-aos="fade-right" data-aos-duration="700">
                            <p class="sub-hero-text">Our Vision</p>
                            <p>To deliver quality medicines at the lowest price to every home within 3 hours.</p>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 fl-eq-c">
                        <div data-aos="fade-left" data-aos-duration="700">
                            <p class="sub-hero-text">Our Mission</p>
                            <p>Delivering quality medicines while following ethical practices through the adoption of
                                the latest technologies, on-time delivery, regular reminders and follow-up.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

export default {
    name: 'About',
    components: {}
};
</script>

<style scoped lang="scss">

.bg-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    .layout-4 {
        position: absolute;
        left: 0;
        top: 0;
        width: 10%;
    }

    .layout-6 {
        position: absolute;
        right: 0;
        bottom: 20px;
        width: 8%;
    }

    .layout-8 {
        position: absolute;
        right: 0;
        top: 0;
        width: 40%;
    }
}
</style>
