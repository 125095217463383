<template>
    <div class="home">
        <div class="pos-r c-bg-1">
            <div class="bg-container">
                <img class="layout-3" src="../assets/images/layout/layout5.png" alt="">
                <img class="layout-1" src="../assets/images/layout/layout1.png" alt="">
                <img class="layout-4" src="../assets/images/layout/layout4.png" alt="">
                <img class="layout-5" src="../assets/images/layout/layout5.png" alt="">
                <img class="layout-6" src="../assets/images/layout/layout2.png" alt="">
            </div>
            <div class="container py-5 h-100vh fl-eq-c">
                <div class="row mt-4">
                    <div class="col-12 col-lg-6 fl-x-cc mb-5 mb-lg-0">
                        <img src="../assets/images/banner1.png" class="img-fluid hero-img" data-aos="zoom-in"
                             data-aos-duration="700" alt="">
                    </div>
                    <div class="col-12 col-lg-6 order-lg-first fl-eq-c text-center text-lg-left">
                        <div data-aos="fade-right" data-aos-duration="700">
                            <p class="hero-text">Medico -<br>
                            <span>"Partner for better health"</span>
                            </p>
                            <p>Medico delivers medicine @home, on time, everytime. Founded by professionals in the pharmaceutical industry with over 25 years of experience, Medico
                                is your one-stop solution for all your medicine needs. Call us for same day delivery and attractive discounts!</p>
                        </div>
                    </div>
                    <!--                    <parallax direction="up" speedFactor="0.15">-->
                    <!--                        <img src="../assets/images/bg.svg" class="img-fluid" alt="very cool bg">-->
                    <!--                        <p>-->
                    <!--                            sdjfkhdf-->
                    <!--                        </p>-->
                    <!--                    </parallax>-->
                </div>
            </div>
            <div class="container py-5 fl-eq-c">
                <div>
                    <Heading heading="Services"
                             headingdesc=""/>
                    <div class="row px-lg-5">
                        <div class="col-12 col-md-6 fl-x-c">
                            <div>
                                <offer-details img="1.png" desc="Flat 15% discount"/>
                                <offer-details img="2.png" desc="Fast and free door delivery within 24 hours"/>
                                <offer-details img="3.png" desc="Multiple payment options"/>
                                <offer-details img="4.png" desc="Easy return policy"/>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 ">
                            <div>
                                <offer-details img="5.png" desc="More than 1 lakh genuine brands"/>
                                <offer-details img="6.png" desc="Special discount for healthcare workers"/>
                                <offer-details img="7.png" desc="Attractive loyalty scheme"/>
                                <offer-details img="8.png" desc="State of the art cold chain delivery system"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--        <div class="pos-r">-->
        <!--            <div class="bg-container">-->
        <!--                <img class="layout-8" src="../assets/images/layout/layout6.png" alt="">-->
        <!--            </div>-->
        <!--            <div class="container py-5 fl-eq-c">-->
        <!--                <div>-->
        <!--                    <Heading heading="The Way We Work"/>-->
        <!--                    <div class="row px-lg-5">-->
        <!--                        <div class="col-12 fl-x-cc" data-aos="zoom-in" data-aos-duration="600">-->
        <!--                            <img src="../assets/images/flow.png" class="img-fluid flow-img" alt="#">-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->

        <div class="pos-r">
            <div class="bg-container">
                <img class="layout-8" src="../assets/images/layout/layout6.png" alt="">
            </div>
            <div class="container py-5 fl-eq-c">
                <div>
                    <Heading heading="The Way We Work"/>
                    <div class="row px-lg-5" data-aos="zoom-in" data-aos-duration="1000">
                        <div class="col-12 fl-x-cc">
                            <div class="flow-icon-wrapper">
                                <img src="../assets/images/flow/path.png" class="img-fluid flow-img" alt="#">
                                <div class="flow-icon-container">
                                    <box class="flow-icon flow-icon-01" padding="0" #default="{hover}">
                                        <img src="../assets/images/flow/01.png" :class="{'scaleUp': hover}" class="img-fluid"
                                             alt="#">
                                    </box>
                                    <box class="flow-icon flow-icon-02" padding="0" #default="{hover}">
                                        <img src="../assets/images/flow/02.png" :class="{'scaleUp': hover}" class="img-fluid"
                                             alt="#">
                                    </box>
                                    <box class="flow-icon flow-icon-03" padding="0" #default="{hover}">
                                        <img src="../assets/images/flow/03.png" :class="{'scaleUp': hover}" class="img-fluid"
                                             alt="#">
                                    </box>
                                    <box class="flow-icon flow-icon-04" padding="0" #default="{hover}">
                                        <img src="../assets/images/flow/04.png" :class="{'scaleUp': hover}" class="img-fluid"
                                             alt="#">
                                    </box>
                                    <box class="flow-icon flow-icon-05" padding="0" #default="{hover}">
                                        <img src="../assets/images/flow/05.png" :class="{'scaleUp': hover}" class="img-fluid"
                                             alt="#">
                                    </box>
                                    <box class="flow-icon flow-icon-06" padding="0" #default="{hover}">
                                        <img src="../assets/images/flow/06.png" :class="{'scaleUp': hover}" class="img-fluid"
                                             alt="#">
                                    </box>
                                    <box class="flow-icon flow-icon-07" padding="0" #default="{hover}">
                                        <img src="../assets/images/flow/07.png" :class="{'scaleUp': hover}" class="img-fluid"
                                             alt="#">
                                    </box>
                                    <box class="flow-icon flow-icon-08" padding="0" #default="{hover}">
                                        <img src="../assets/images/flow/08.png" :class="{'scaleUp': hover}" class="img-fluid"
                                             alt="#">
                                    </box>
                                    <box class="flow-icon flow-icon-09" padding="0" #default="{hover}">
                                        <img src="../assets/images/flow/09.png" :class="{'scaleUp': hover}" class="img-fluid"
                                             alt="#">
                                    </box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pos-r c-bg-1">
            <div class="bg-container">
                <img class="layout-7" src="../assets/images/layout/layout3.png" alt="">
            </div>
            <div class="container py-5">
                <div>
                    <Heading heading="Our Equipments"/>

                    <div class="">
                        <swiper class="swiper equipments-slider my-4" :options="swiperOption">
                            <swiper-slide>
                                <equipments img="01.png" name="Blood Glucose Monitor"/>
                            </swiper-slide>
                            <swiper-slide>
                                <equipments img="02.png" name="Blood Pressure Monitor"/>
                            </swiper-slide>
                            <swiper-slide>
                                <equipments img="03.png" name="Nebulizer"/>
                            </swiper-slide>
                            <swiper-slide>
                                <equipments img="04.png" name="Blood Pressure Monitor"/>
                            </swiper-slide>
                            <swiper-slide>
                                <equipments img="05.png" name="Blood Glucose Monitor"/>
                            </swiper-slide>

                            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                        </swiper>
                    </div>

                </div>
            </div>
            <div class="bg-2">
                <div class="container py-5">
                    <div>
                        <Heading heading="Interactive Platform"
                                 headingdesc="You may share your problems, obstacles, complaints, suggestions and solutions if any with us through our website, thereby together we can develop ways and means to build a health protected society around us."/>

                        <div class="">
                            <swiper class="swiper community-slider my-4" :options="swiperOption1">
                                <swiper-slide>
                                    <community person_name="Narayanan Namboodiri" place="Peramangalam"
                                               comment="I have been taking medicines for BP and sugar regularly and my family has allergic complaints for which the treatment is ON. Sometimes, we have to go to Thrissur to purchase medicines and due to Covid many days we could not travel. When I saw the advertisement of Medico Pharma, I called them and got my medicines at home."/>
                                </swiper-slide>
                                <swiper-slide>
                                    <community person_name="Thomas Philip" place="Arimbur"
                                               comment="I am a cancer patient, now at the recovery stage. Afraid to travel for infections. So, contacted Medico Pharma, and now, they call me when the medicine is expected to be exhausted. Nice and prompt treatment like a nurse at home."/>
                                </swiper-slide>
                                <swiper-slide>
                                    <community person_name="Meenakshi Menon " place="Pudukkad"
                                               comment="Retired High School Principal.   I am taking medicines for joint pain, rheumatic arthritis and muscle cramps.  So many medicines are only available in Swaraj Round.  But, now I have no worry.  I have two daughters and the younger one is employed and stays at home.  But, anytime of the day it is safe to call Medico Pharma and ladies do not have to be afraid.  They treat us like own family member."/>
                                </swiper-slide>
                                <swiper-slide>
                                    <community person_name="Angela" place="Nadkkavu"
                                               comment="Works at home in IT company. I work for almost 14-16 hours sometimes in a day.  I have spontillosis complaint and depression symptoms due excess work. I used to tell my neighbor to buy me medicines from Thrissur.  But now, Medico Pharma keeps a record of the stock of my medicines and remind me.  A trustworthy negihbour with their presence everywhere in Thrissur.  I wish them to spread to Kerala and beyond."/>
                                </swiper-slide>

                                <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
                            </swiper>
                        </div>

                    </div>
                </div>
            </div>
            <div class="container py-5 fl-eq-c">
                <div>
                    <div class="row">
                        <div class="col-12 col-lg-4 fl-y-cc">
                            <div class="text-center text-lg-left">
                                <p class="mb-4 underline u-primary u-c u-lg-l font-weight-bold fs-6">Our Offer For
                                    You</p>
                                <p> We have a team of experienced and committed team joined together from various
                                    sectors of pharmaceutical field to serve you and treat you better while ensuring
                                    high customer satisfaction.</p>
                            </div>
                        </div>
                        <template v-for="i in items">
                            <div class="col-12 col-md-6 col-lg-4 mb-4" v-bind:key="i.id" data-aos="slide-up" data-aos-duration="600">
                                <offer-box :img="i.img"/>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
// import Parallax from 'vue-parallaxy';

import OfferDetails from './components/offer-details';
import Heading from './components/Heading';
import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import Equipments from './components/equipments';
import Community from './components/community';
import OfferBox from './components/offer-box';

export default {
    name: 'Home',
    components: {
        OfferBox,
        Community,
        Equipments,
        Swiper,
        SwiperSlide,
        Heading,
        OfferDetails
        // Parallax
    },
    data () {
        return {
            swiperOption: {
                autoHeight: true,
                slidesPerView: 3,
                loop: true,
                spaceBetween: 15,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                autoplay: {
                    delay: 8000,
                    disableOnInteraction: false
                },
                breakpoints: {
                    1200: {
                        speed: 1500
                    },
                    1024: {
                        slidesPerView: 3,
                        speed: 1000
                    },
                    768: {
                        slidesPerView: 2,
                        speed: 800
                    },
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 800
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 800
                    }
                }
            },
            swiperOption1: {
                loop: true,
                spaceBetween: 15,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                autoplay: {
                    delay: 8000,
                    disableOnInteraction: false
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    1200: {
                        slidesPerView: 3,
                        speed: 1500
                    },
                    1024: {
                        slidesPerView: 3,
                        speed: 1000
                    },
                    768: {
                        slidesPerView: 2,
                        speed: 800
                    },
                    640: {
                        slidesPerView: 1,
                        speed: 800
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 800
                    }
                }
            },
            items: [
                // {
                //     id: '1',
                //     img: '01.png'
                // },
                {
                    id: '2',
                    img: '02.png'
                },
                {
                    id: '3',
                    img: '03.png'
                },
                {
                    id: '4',
                    img: '04.png'
                },
                {
                    id: '5',
                    img: '05.png'
                }
            ]
        };
    }
};

</script>
<style scoped lang="scss">
.bg-banner {
    background-image: url('../assets/images/layout/layout1.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-banner-2 {
    //background-image: url('../assets/images/bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    .layout-1 {
        position: absolute;
        right: 0;
        top: 0;
        width: 55%;
    }

    .layout-3 {
        position: absolute;
        right: 0;
        top: 48%;
        width: 40%;
        transform: rotate(180deg);
        z-index: 0;
    }

    .layout-4 {
        position: absolute;
        left: 0;
        top: 0;
        width: 10%;
    }

    .layout-5 {
        position: absolute;
        left: 0;
        bottom: 5px;
        width: 40%;
    }

    .layout-6 {
        position: absolute;
        right: 0;
        bottom: -15%;
        width: 10%;
    }

    .layout-7 {
        position: absolute;
        right: 0;
        top: 3%;
        height: 100%;
    }

    .layout-8 {
        position: absolute;
        left: 0;
        top: 5px;
        width: 40%;
        transform: scaleY(-1);
    }
}

@media (min-width: 1420px) {
    .bg-container {

        .layout-3 {
            position: absolute;
            right: 0;
            top: 53%;
            width: 40%;
            transform: rotate(180deg);
            z-index: 0;
        }

    }
}
</style>
